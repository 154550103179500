<template>
<div class="w-1200">
  <tag-big :navData="navData" :activeChildName="type==0?'company':'business'" :self="true"></tag-big>
  <div class="cont clear" v-if="info">
    <div class="wid-75 border-right clear">
      <div class="title-div">
        <h3>{{info.title}}</h3>
        <p>{{info.createTime.split(' ')[0]}}</p>
      </div>
      <div class="data-txt">
        <div v-html="htmlData"></div>
      </div>
    </div>
    <div class="wid-25">
      <div class="stick" :style="{top:top+'px'}">
        <button @click="toList">返回{{type==0?'公司新闻':'业务动态'}} </button>
        <button @click="toTop">返回顶点</button>
      </div>
    </div>
  </div>
<!--  <h4 class="back-top mb-60" @click="toTop"><span>回到顶部</span><em></em></h4>-->
</div>
</template>

<script>
import TagBig from '@/components/tag_big'
import {
  newsDetail,
  newsNext
} from '@/api/index.js'

export default {
  name: 'news-detail',
  metaInfo: {
    title: '新闻资讯'
  },
  components: {
    TagBig
  },
  data() {
    return {
      top:0,
      type:0,
      htmlData: '',
      info: null,
      id: this.$route.query.id,
      navData: {
        categoryName: '新闻资讯',
        f_link: '',
        children: [{
          categoryName:"公司新闻",
          path:"/news_list/company",
          type:"company"
        },{
          categoryName:"媒体报导",
          path:"/news_list/business",
          type:"business"
        }]
      },
    }
  },
  created() {
    newsDetail(this.id).then(res => {
      if (res) {
        this.type=res.category
        this.info = res
        this.htmlData = res.content
      }
    })
  },
  mounted() {
    this.handleScroll();
    window.addEventListener("scroll", this.handleScroll);
  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.handleScroll, false);
  },
  methods: {
    handleScroll() {
      let scrollTop =
          window.pageYOffset ||
          document.documentElement.scrollTop ||
          document.body.scrollTop;
      if (scrollTop > 400) {
        this.top = scrollTop-400;
      } else {
        this.top = 0;
      }
    },
    toList(){
      this.$router.push({
        path: '/news_list/'+(this.type==0?'company':'business')
      })
    },
		toTop(){
			window.scrollTo(0, 0);
		},
    tipPop() {
      (this.GLOBAL.warningPop)(this, '正在建设中，敬请期待。')
    },
    newsNextAction(action) {
      newsNext(action, this.id, 0).then(res => {
        if (res) {
          this.id = res.articleId
          this.$router.push({
            path: '/news_detail?id=' + res.articleId + '&page=' + this.$route.query.page
          })
          this.info = res
          this.htmlData = res.content
        } else {
          if (action == 0) {
            (this.GLOBAL.warningPop)(this, '已是第一条')
          } else {
            (this.GLOBAL.warningPop)(this, '已是最后一条')
          }
        }
      })
    }
  }
}
</script>

<style lang="less" scoped>
@import '../../assets/css/params.less';
.stick{
  padding-top: 70px;
  position: absolute;
  top:0;
  transition: all .3s;
  button{
    width: 193px;
    height: 38px;
    background: #eeeeee;
    line-height: 38px;
    text-align: center;
    color: #333333;
    font-size: 13px;
    margin-bottom: 24px;
    margin-left: 34px;
  }
}
.data-txt {
    padding-right:40px;
}
.cont {
    background-color: #fff;
  position: relative;
  .wid-75{
    padding-bottom: 70px;
  }
    .title-div {
      padding-top: 40px;
      padding-bottom: 40px;
      h3 {
        font-size: 17px;
        color: @main1;
        margin-bottom: 12px;
      }
      p{
        font-size: 25px;
        font-weight: 700;
        color: #c8c8c8;
        line-height: 33px;
      }
    }
}
.back-top{
	display: none;
}
@media screen and (max-width: 1024px) {
  .wid-75{
    width: 100%;
    border: none;
    padding-bottom:50px!important;
  }
  .wid-25{
    display: none;
  }
		.back-top{
			display: block;
			background-color: #b4b4b4;
			line-height: 48px;
			height: 48px;
			color: #fff;
			text-align: center;
			font-size: 20px;
			position: relative;
			span{
				display: inline-block;
			}
			em{
				display: inline-block;
				width: 18px;
				height:14px;
				background-image: url('/static/img/iphone/up.png');
				background-size: 100% 100%;
				margin-left: 7px;
				transition: all .3s;
				transform-origin: 50% 50%;
			}
		}
    .cont {
      .title-div {
        padding:0 22px;
        border: none;
        h3{
          font-size: 16px;
          padding-top: 30px;
          &:after{
            display: none;
          }
        }
        p{
          font-size: 12px;
          font-weight: normal;
        }
      }
      .tag-div {
        height:40px;
        width: 100%;
        margin-bottom: 4px;
          > h4 {
            height:40px;
            display: block;
            color: @main1;
            line-height: 40px;
            span:nth-of-type(2) {
              display: none;
            }
          }
          > ul {
              display: none;
          }
      }
    }
}
</style>
